import React from 'react';
import banner from './assets/banner.jpg'

const images = require.context('./assets/img', false, /\.(png|jpe?g|svg)$/);

const importImage = (name) => {
  try {
    return images(`./${name}.png`);
  } catch (err) {
    console.error(`Image not found: ${name}`, err);
    return null;
  }
};

const imageNames = ['icon1', 'icon2', 'icon3', 'icon4', 'icon5', 'icon6'];

function App() { 

  const goToByScroll = (id) => {
    const element = document.querySelector(id);
    if (element) {
      // Scroll to the element with smooth behavior
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
     <>
     <div id="banner" style={{ backgroundImage: `url(${banner})`,height: '500px',backgroundSize: 'cover' }}  className="container-fluid d-flex justify-content-center align-items-center"> 

          <button className="button btn btn-danger rounded-pill"onClick={() => goToByScroll('#contacts')} >Work with us</button>
      </div>

      <div id="services" className="container-fluid p-5">
        <div className="welcome text-center">
          <h1>Welcome to Incep Infotech</h1>
          <h2>We Create Stunning Websites</h2>
        </div>

        <div className="container service-body p-5">

          <div className="row">
            <div className="col">
            <img  src={importImage(imageNames[0])} alt="Website Designing" />
              <h3>Website <b>Designing</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
            <div className="col"> 
              <img src={importImage(imageNames[1])} alt="Website Development" />
              <h3>Website <b>Development</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
            <div className="col">
            <img src={importImage(imageNames[2])} alt="Ecommerce Solutions" />
               <h3>Ecommerce <b>Solutions</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
          </div>
          
          <div className="row">
            <div className="col">
            <img  src={importImage(imageNames[0])} alt="Mobile Apps" />
              <h3>Mobile <b>Apps</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
            <div className="col"> 
            <img  src={importImage(imageNames[0])} alt="Search Engine Optimization" />
              <h3>Search Engine <b>Optimization</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
            <div className="col">
            <img  src={importImage(imageNames[0])} alt="Wordpress Development" />
               <h3>Wordpress <b>Development</b></h3>
              <p>The design of a website reflects the brand it is built for and we let you create it to the best by blending different technologies as per your business' unique requirements.</p>
            </div>
          </div>

        </div>

        <div className="service-footer">
           <p>We deliver visually appealing, innovative and feature-rich web solutions centered towards your business.</p>
           <p>Our Incep infotech programs are implemented to drive real growth to your business.</p>
           <p>You can expect highest standards of technical excellence and true professionalism from us.</p>
            
           <div className="p-4">
              <button className="button btn btn-outline-danger rounded-pill" onClick={() => goToByScroll('#services')} >Portfolio </button>&nbsp;
              <button className="button btn btn-outline-danger rounded-pill"onClick={() => goToByScroll('#contacts')} >Contact us</button>
            </div>
 
        </div>

      </div>

      <div id="contacts" class="container p-5">

        <div class="row"> 
          <div class="col-8">
              <h3>Lets Get Started your project</h3>
              <h6>We will help you to achieve your goals and to grow your business.</h6>
 
              <table class="table"> 
                <thead>
                  <tr>
                    <th colSpan={2}>CONTACT US</th>
                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    <td><span class="badge rounded-pill bg-danger"><i class="fa fa-map-marker"></i></span></td>
                    <td>
                      <h6>Our location</h6>
                      <p>E331, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p> 
                    </td> 
                  </tr> 
                  <tr> 
                    <td><span class="badge rounded-pill bg-danger"><i class="fa fa-envelope-o"></i></span></td>
                    <td>
                      <h6>Your message</h6>
                      <a href='mailto:career@incep.in'>career@incep.in</a> 
                    </td> 
                  </tr> 
                  
                  <tr> 
                    <td>
                      <a title='Facebook' href='https://www.facebook.com/'><span class="badge rounded-pill bg-primary"><i class="fa fa-facebook"></i></span></a>&nbsp; 
                      <a title='Linkedin' href='https://www.linkedin.com/'><span class="badge rounded-pill bg-dark"><i class="fa fa-linkedin"></i></span></a></td> 
                  </tr> 
                </tbody>
              </table>
          </div>
          <div class="col">
             <button className="button btn btn-outline-danger rounded-pill">REQUEST A QUOTE</button>

             <table class="table"> 
                <thead>
                  <tr>
                    <th>QUICK LINKS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    <td>
                        <a href="/" onClick={(e) => { e.preventDefault(); goToByScroll('#root'); }}>Home</a><br/>
                        <a href="/" onClick={(e) => { e.preventDefault(); goToByScroll('#services'); }}>About Us</a><br/>
                        <a href="/" onClick={(e) => { e.preventDefault(); goToByScroll('#services'); }}>Services</a><br/>
                        <a href="/" onClick={(e) => { e.preventDefault(); goToByScroll('#contacts'); }}>Contact Us</a><br/>
                    </td> 
                  </tr>  
                </tbody>
              </table>

          </div>
        </div>
        
      </div>

     </>
  );
}

export default App;
