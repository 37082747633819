 
function Footer() {
    return (
      <> 
         <div className="container-fluid bg-dark p-2">
                <div class="copyright">
                    <a href="/">©2024 All rights reserved to <span>Incep Infotech</span> | Terms &amp; Conditions | Privacy Policy </a>
                </div>
        </div>
      </>
    );
  }
  
  export default Footer;