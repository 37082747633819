import logo from '../assets/logo.svg'
import '../assets/style.css';

function Header() {
    const goToByScroll = (id) => {
        const element = document.querySelector(id);
        if (element) {
          // Scroll to the element with smooth behavior
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
    return (
      <> 
      <div className="container">
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><img width="100px" src={logo} alt="Logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                     
                    <div className="ms-auto"> 
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item"><a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); goToByScroll('#root'); }}>HOME</a></li>
                            <li className="nav-item"><a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); goToByScroll('#services'); }}>SERVICES</a></li>  
                            <li className="nav-item"><a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); goToByScroll('#services'); }}>ABOUT US</a></li>  
                            <li className="nav-item"><a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); goToByScroll('#contacts'); }}>CONTACT</a></li>  
                            <li className="nav-item"><a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); goToByScroll('#contacts'); }}><b>GET A QUOTE</b></a></li>   
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:career@incep.in"><i class="fa fa-envelope-o"></i> career@incep.in</a>
                            </li> 
                        </ul>
                    </div> 
                </div>
            </div>
            </nav> 
            </div>
      </>
    );
  }
  
  export default Header;